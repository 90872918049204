import React from 'react';
import { PageProps } from 'gatsby';
import { Typography } from 'antd';
import Layout from '@/components/layout';

const GalleryPage: React.FC<PageProps> = () => {
  React.useEffect(() => {
    const loadES = (doc: Document, id) => {
      if (doc.getElementById(id)) {
        doc.getElementById(id).remove();
      }
      const js: HTMLScriptElement = doc.createElement(`script`);
      js.id = id;
      js.src = `https://embedsocial.com/cdn/ht.js`;
      doc.getElementsByTagName(`head`)[0].appendChild(js);
    };
    loadES(document, `EmbedSocialHashtagScript`);
  }, []);

  return (
    <Layout expand={false}>
      <Typography.Title level={1}>Galerie</Typography.Title>
      <div
        className="embedsocial-hashtag"
        data-ref="449a6f1a94cabb004d1b801055fb913562f69caf"
      />
    </Layout>
  );
};
export default GalleryPage;
